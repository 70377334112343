<template>
  <div>
    <el-dialog
      v-if="centerDialogVisible"
      :close-on-click-modal="false"
      :show-close="false"
      :title="title"
      :width="width + 'px'"
      class="fw600"
      style="border-radius: 10px; margin-top: 100px"
      :visible.sync="centerDialogVisible"
      center
      :before-close="cancel"
    >
      <div class="texts f14"><slot></slot></div>
      <div class="df" style="flex-direction: row-reverse">
        <!-- <div class="dCenter btn mouse" 
          @click="affirm"
          style="margin-left: 20px;background-color:#E33F57 ;color: #ffffff;border: none;">确认</div>
      <div class="dCenter btn mouse" @click="cancel">取消</div> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "XitongPopup",
  // props: ["dialog", "title", "width",'close'],
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 400,
    },
    closes: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      centerDialogVisible: this.dialog,
    };
  },

  mounted() {},
  watch: {
    // 监听父组件数据,触发改变事件,不能直接改变
    dialog() {
      this.centerDialogVisible = !this.centerDialogVisible;
    },
  },
  methods: {
    // 确定 点击时触发父组件里面的事件
    affirm() {
      this.$emit("affirm");
    },
    // 取消
    cancel() {
      this.$emit("cancel", false);
      // this.centerDialogVisible = false
    },
  },
};
</script>

<style lang="scss" scoped>
.texts {
  color: #4d4d4d;
  margin: 0 0 30px 0;
  font-weight: 400 !important;
  line-height: 20px;
}
.btn {
  width: 100px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #1a1a1a;
}
</style>
