import Vue from "vue";
import Cookies from "js-cookie";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import TDesign from 'tdesign-vue';
// // 引入组件库的少量全局样式变量
// import 'tdesign-vue/es/style/index.css';
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import i18n from "./lang";
import "./assets/font/font.css";
import './permission' // 路由守卫
import { Toast } from "vant";
import "vant/lib/index.css";
import VueCropper from "vue-cropper";
import Popup from "@/components/Popup";
import publics from "./utils/public";
const VUE_APP_VERSION = require("../package.json").version;
const vers = window.localStorage.getItem("appVersion");
console.log(VUE_APP_VERSION)
console.log(vers)
if (VUE_APP_VERSION != vers) {
  localStorage.removeItem("appVersion");
  window.localStorage.setItem("appVersion", VUE_APP_VERSION);
  location.reload();
}
Vue.prototype.publics = publics;
Vue.component("Popup", Popup);
Vue.use(VueCropper);
Vue.config.productionTip = false;
Vue.use(Toast);

Vue.use(Element, {
  size: Cookies.get("size") || "medium", // set element-ui default size
  i18n: (key, value) => i18n.t(key, value),
});
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
