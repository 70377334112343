import { getToken, getWithExpiry, getUser } from "@/utils/auth";

export default {
  //调试
  loadErudaJs() {
    let script = document.createElement("script");
    script.src = "https://cdn.bootcss.com/eruda/1.3.0/eruda.min.js";
    script.type = "text/javascript";
    document.getElementsByTagName("head")[0].appendChild(script);
    script.onload = function () {
      eruda.init();
    };
  },
  //时间格式化
  formatDate(time, id) {
    function add0(m) {
      return m < 10 ? "0" + m : m;
    }
    var time = new Date(time);
    var y = time.getFullYear();
    var m = time.getMonth() + 1;
    var d = time.getDate();
    var h = time.getHours();
    var mm = time.getMinutes();
    var s = time.getSeconds();
    if (id == 0) {
      return y + "-" + add0(m) + "-" + add0(d);
    } else if (id == 1) {
      return y + "/" + add0(m) + "/" + add0(d) + "/";
    }

    // return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
  },

  formTime(time) {
    const now = Date.now();
    const diff = now - new Date(time).getTime();
    const minutes = Math.round(diff / 1000 / 60); //分钟
    let h;
    let result = "";
    if (minutes < 5) {
      result = "刚刚";
    } else if (minutes <= 60) {
      result = `${minutes}分钟前`;
    } else if (minutes < 120) {
      result = "1小时前";
    } else if (minutes > 120) {
      h = Math.round(minutes / 60);
      if (h < 23) {
        result = `${h}小时前`;
      } else if (h < 48) {
        result = "一天前";
      } else {
        result = this.formatDate(time, 0);
      }
    }
    return result;
  },
  /*
   * 验证手机号是否合格
   * true--说明合格
   */
  isPhone(phoneStr) {
    let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    if (!myreg.test(phoneStr)) {
      return false;
    } else {
      return true;
    }
  },

  /**
   * @desc  函数防抖，用于将多次执行变为最后一次执行
   * @param {function} func - 需要使用函数防抖的被执行的函数。必传
   * @param {Number} wait - 多少毫秒之内触发，只执行第一次，默认1000ms。可以不传
   */
  debounce(fn, delay) {
    delay = delay || 1000; //默认1s后执行
    let timer = null;
    return function () {
      let context = this;
      let arg = arguments;
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        fn.apply(context, arg);
      }, delay);
    };
  },

  /**
   * 节流函数, 用于将多次执行变为每隔一段时间执行
   * @param fn 事件触发的操作
   * @param delay 间隔多少毫秒需要触发一次事件
   */
  throttle2(fn, delay) {
    let timer = null;
    return function () {
      let context = this;
      let args = arguments;
      if (!timer) {
        timer = setTimeout(function () {
          fn.apply(context, args);
          clearTimeout(timer);
        }, delay);
      }
    };
  },

  /*
   *数字每千位加逗号
   *
   */
  commafy(num) {
    return (
      num &&
      num.toString().replace(/\d+/, function (s) {
        return s.replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      })
    );
  },
  /*
   *数字千、万处理
   *
   */
  numfix(num) {
    return num / 10000 >= 1
      ? (num / 10000).toFixed(1) + "w"
      : num / 1000 >= 1
      ? (num / 1000).toFixed(1) + "k"
      : num;
  },
  //token校验
  onToken() {
    

    if (
      getWithExpiry() != undefined &&
      getWithExpiry() != null &&
      getUser() != undefined
    ) {
      return true;
    } else {
      return false;
    }
  },
};
