<template>
  <el-dropdown trigger="click" class="international" @command="handleSetLanguage">
    <div>
      <img class="lang_default" :class="color_type==1?'':'lang_img'" :src="color_type==1?langb:lang" alt="">
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item :disabled="language === 'zh'" command="zh">
        中文
      </el-dropdown-item>
      <el-dropdown-item :disabled="language === 'en'" command="en">
        English
      </el-dropdown-item>
      <el-dropdown-item :disabled="language === 'es'" command="es">
        Español
      </el-dropdown-item>
      <el-dropdown-item :disabled="language === 'ja'" command="ja">
        日本語
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import lang from "../../assets/lang.png";
import langb from "../../assets/langb.png";
export default {
  props: {
    color_type: {
      type: Number,
      value:0
    }
  },
  data() {
    return {
      lang:lang,
      langb:langb,
    }
  },
  computed: {
    language() {
      return this.$store.getters.language
    }
  },
  created() {

  },
  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang
      this.$store.dispatch('app/setLanguage', lang)
      this.$message({
        message: 'Switch Language Success',
        type: 'success'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.lang_default{
  width: 20px;
  height: 20px;
  position: absolute;
}
.lang_img {
  top: 20px;
  right: 20px
}

</style>