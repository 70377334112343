<template>
    <div style="display: flex;">
        <!-- <el-avatar fit="cover" class="avatar" :style="{ width: size + 'px', height: size + 'px' }" :src="imgUrl"
            @error="errorHandler">
            <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" />
        </el-avatar> -->
        <el-image @click.prevent="getJump()" fit="cover" class="avatar"
         :style="{ width: size + 'px', height: size + 'px' }"
          :src="imgUrl=='N/A'?avatarDef:type==2?imgUrl+'?timestamp='+timestamp:imgUrl">
            <div slot="error" class="image-slot">
                <img @click.prevent="getJump()" :style="{ width: size + 'px', height: size + 'px' }"
                    src="@/assets/error/avatar.png" />
            </div>
        </el-image>
    </div>
</template>

<script>
import avatarDef from "@/assets/common/avatar-def.png";

export default {
    props: {
        size: {
            type: Number,
            default: 16,
        },
        imgUrl: {
            type: String,
            default: "",
        },
        userId: {
            type: String,
            default: '',
        },
        type: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            timestamp: new Date().getTime(),
            avatarDef:avatarDef
        };
    },
    created() { },
    mounted() { 
    },
    methods: {
        getJump(){
            if(this.userId!=''){
                this.$router.push({ path: '/userHomepage', query: { uid: this.userId } });
            }
        },
        errorHandler() {
            return true
        }
    },
};
</script>
<style scoped lang="scss">
.avatar {
    border-radius: 50%;
    cursor: pointer;
}

.error {}
</style>
