import Cookies from 'js-cookie'
const state = {
  menuId: 0,
  returnAddress:'',
  search:'',
}

const mutations = {

  SET_ID: (state, menuId) => {
    Cookies.set('menuId', menuId, { expires: 60 })
    state.menuId = menuId
  },
  SET_ADDRESS: (state, returnAddress) => {
    state.returnAddress = returnAddress
    Cookies.set('returnAddress', returnAddress)
  },
  SET_SEARCH: (state, search) => {
    Cookies.set('search', search)
    state.search = search
  },
}

const actions = {

  // setSize({ commit }, id) {
  //   commit('SET_ID', id)
  // }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
